import { CameraOptions } from 'maplibre-gl';
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useControl } from 'react-map-gl';

const FLAT_STATE: CameraOptions = {
  pitch: 0,
};

const IMMERSIVE_STATE: CameraOptions = {
  pitch: 45,
};

function createElement(onClick: () => void, children: ReactNode) {
  return (
    <button type="button" onClick={onClick} aria-label="3D view">
      {children}
    </button>
  );
}

interface ThreeDControlProps {
  onChange: Dispatch<SetStateAction<CameraOptions>>;
}

const ThreeDControl = ({ onChange }: ThreeDControlProps) => {
  const [is3D, setIs3D] = useState(true);

  useEffect(() => {
    onChange((value: CameraOptions) => ({ ...value, ...(is3D ? IMMERSIVE_STATE : FLAT_STATE) }));
  }, [is3D, onChange]);

  useControl(
    () => {
      return {
        onAdd: (map) => {
          const container = document.createElement('div');
          container.id = '3d-control';
          container.className = 'maplibregl-ctrl maplibregl-ctrl-group mapboxgl-ctrl mapboxgl-ctrl-group custom-control';
          const root = createRoot(container);
          const callback = () => {
            setIs3D((value: boolean) => !value);
            root.render(createElement(callback, map.getPitch() === 0 ? '3D' : '2D'));
          };
          root.render(createElement(callback, is3D ? '3D' : '2D'));
          return container;
        },
        onRemove: (map) => {
          document.getElementById('3d-control')?.remove();
        },
      };
    },
    {
      position: 'top-right',
    },
  );
  return null;
};

export default ThreeDControl;
