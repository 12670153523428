import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import { Marker, MarkerProps } from 'react-map-gl';
import styled from 'styled-components';
import markerGray from '../../assets/marker-gray.svg';
import markerGreen from '../../assets/marker-green.svg';

type Size = 'big';
type Color = 'green';

interface CustomMarkerProps {
  size?: Size;
  color?: Color;
  icon: IconProp;
  alt: string;
}

const CustomMarkerWrapper = styled.div<CustomMarkerProps>`
  position: relative;
  svg {
    height: 12px;
    color: white;
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    ${(props) => {
      switch (props.size) {
        case 'big': {
          return `
            height: 18px;
            top: 12px;
        `;
        }
      }
    }}
  }
`;

function CustomMarker({ size, color, icon, alt, ...markerProps }: CustomMarkerProps & MarkerProps) {
  return (
    <Marker {...markerProps}>
      <CustomMarkerWrapper size={size} color={color} icon={icon} alt={alt}>
        <Image
          src={color === 'green' ? markerGreen : markerGray}
          alt={alt}
          width={size === 'big' ? '40' : '24'}
          height={size === 'big' ? '60' : '36'}
        />
        <FontAwesomeIcon icon={icon} />
      </CustomMarkerWrapper>
    </Marker>
  );
}

export default CustomMarker;
