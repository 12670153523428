import Image from 'next/image';
import Link from 'next/link';
import { Popup, PopupProps } from 'react-map-gl';
import styled from 'styled-components';
import { Button } from 'legacy-ui';
import { Text } from 'legacy-ui';
import React from 'react';

interface CustomPopupProps {
  image?: string | React.ReactElement;
  headline?: string;
  description?: string;
  buttonLink?: string;
  buttonText?: string;
}

const CustomPopupWrapper = styled(Popup)<CustomPopupProps>`
  z-index: 3;
  .mapboxgl-popup-content,
  .maplibregl-popup-content {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 0;
  }
`;

const CustomPopupImage = styled.div`
  height: 132px;
`;

const CustomPopupContent = styled.div`
  padding: 16px 16px 4px 16px;
  p {
    &:nth-of-type(1) {
      margin-bottom: 4px;
    }
    &:nth-of-type(2) {
      margin-bottom: 12px;
    }
  }
  button {
    margin-bottom: 12px;
  }
`;

function CustomPopup({ image, headline, description, buttonLink, buttonText, ...popupProps }: CustomPopupProps & PopupProps) {
  const isImageElement = React.isValidElement(image);

  return (
    <CustomPopupWrapper {...popupProps}>
      {image && (
        <CustomPopupImage>{isImageElement ? image : <Image src={image.toString()} alt={headline} width={240} height={132} />}</CustomPopupImage>
      )}
      <CustomPopupContent>
        {headline && <Text weight="sb">{headline}</Text>}
        {description && <Text size="s">{description}</Text>}
        {buttonLink && (
          <Link href={buttonLink} passHref>
            <Button color="primary" fullWidth small>
              {buttonText}
            </Button>
          </Link>
        )}
      </CustomPopupContent>
    </CustomPopupWrapper>
  );
}

export default CustomPopup;
