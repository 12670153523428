import { faMap, faSatellite } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RasterLayer, Style, Visibility } from 'mapbox-gl';
import { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useControl } from 'react-map-gl';

function createElement(onClick: () => void, children: ReactNode) {
  return (
    <button type="button" onClick={onClick} aria-label="Satellite view">
      {children}
    </button>
  );
}

function updateLayer(layer: RasterLayer, visibility: Visibility) {
  return {
    ...layer,
    layout: {
      ...layer.layout,
      visibility,
    },
  };
}

function updateStyle(style: Style, id: string, visibility: Visibility) {
  return {
    ...style,
    layers: style.layers.map((layer) => (layer.id === id ? updateLayer(layer as RasterLayer, visibility) : layer)),
  };
}

interface BrokenRasterLayer {
  // Somehow the visibility property was moved to the root instead of in the layout object where it belongs.
  visibility: Visibility;
}

interface SatelliteControlProps {
  value: Style;
  onChange: Dispatch<SetStateAction<Style>>;
}

const SatelliteControl = ({ value, onChange }: SatelliteControlProps) => {
  const satelliteLayer = value.layers.find((layer) => layer.id === 'orthophoto') as RasterLayer;
  const [satelliteVisible, setSatelliteVisible] = useState(satelliteLayer!.layout!.visibility === 'visible');

  useEffect(() => {
    const visibility = satelliteVisible ? 'visible' : 'none';
    onChange((current: Style) => updateStyle(current, 'orthophoto', visibility));
  }, [satelliteVisible, onChange]);

  useControl(
    () => ({
      onAdd: (map) => {
        const container = document.createElement('div');
        container.id = 'satellite-control';
        container.className = 'maplibregl-ctrl maplibregl-ctrl-group mapboxgl-ctrl mapboxgl-ctrl-group custom-control';
        const root = createRoot(container);
        const callback = () => {
          setSatelliteVisible((isVisible) => !isVisible);

          root.render(
            createElement(
              callback,
              (map.getLayer('orthophoto')! as unknown as BrokenRasterLayer).visibility === 'visible' ? (
                <FontAwesomeIcon icon={faMap} />
              ) : (
                <FontAwesomeIcon icon={faSatellite} />
              ),
            ),
          );
        };

        root.render(createElement(callback, satelliteVisible ? <FontAwesomeIcon icon={faSatellite} /> : <FontAwesomeIcon icon={faMap} />));
        return container;
      },
      onRemove: (map) => {
        document.getElementById('satellite-control')?.remove();
      },
    }),
    {
      position: 'top-right',
    },
  );
  return null;
};

export default SatelliteControl;
