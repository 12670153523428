import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBasketShopping, faBook, faBriefcaseMedical, faChild, faFutbol, faGraduationCap, faTrain, faTree } from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useState } from 'react';
import { ViewState } from 'react-map-gl';
import { POIListMapResult } from '../../types/map/mapTypes';
import { VIAMAP_ACCESS_TOKEN, VIAMAP_API_URL } from '../../utils/constants';
import CustomMarker from '../MapView/CustomMarker';
import CustomPopup from '../MapView/CustomPopup';

interface POICategory {
  name: string;
  icon: IconDefinition;
  types: string[];
}

interface POI {
  category: POICategory;
  text: string;
  latitude: number;
  longitude: number;
  distance: number;
}

const poiCategories: POICategory[] = [
  { name: 'shopping', icon: faBasketShopping, types: ['supermarket', 'shopping'] },
  { name: 'school', icon: faGraduationCap, types: ['school'] },
  { name: 'daycare', icon: faChild, types: ['daycare'] },
  { name: 'library', icon: faBook, types: ['library'] },
  {
    name: 'transportation',
    icon: faTrain,
    types: ['transportation', 'metro', 'train', 'stop', 'airport', 'roadtrain'],
  },
  { name: 'nature', icon: faTree, types: ['nature', 'lake', 'forest'] },
  { name: 'sports', icon: faFutbol, types: ['sport', 'sportshall', 'soccerfield', 'leisure'] },
  { name: 'health', icon: faBriefcaseMedical, types: ['health', 'hospital', 'clinic'] },
];

interface POIsProps {
  viewState: Partial<ViewState>;
}

const POIs = ({ viewState }: POIsProps) => {
  const [pois, setPois] = useState<POI[]>([]);
  const [popupInfo, setPopupInfo] = useState<POI>();

  useEffect(() => {
    (async () => {
      const url = new URL(`${VIAMAP_API_URL}/getpoi/`);
      url.searchParams.append('token', VIAMAP_ACCESS_TOKEN);
      url.searchParams.append('radius', `${viewState.latitude},${viewState.longitude},2000`);
      url.searchParams.append('poitypes', poiCategories.flatMap(({ types }) => types).join(','));
      url.searchParams.append('limit', '5');
      const response = await fetch(url.toString());
      const json: POIListMapResult = await response.json();
      const foundResults = Object.entries(json)
        .filter(([, value]) => value.numfound > 0)
        .flatMap(([key, value]) => value.POIs.map((poi) => ({ category: key, poi })));

      setPois(
        foundResults.map((result) => {
          const category = poiCategories.find((category) => category.types.includes(result.category))!;
          const [latitude, longitude] = result.poi.poilatlng;
          return {
            category,
            text: result.poi.name,
            latitude,
            longitude,
            distance: result.poi.euclideanmeters,
          };
        }),
      );
    })();
  }, [viewState]);

  return (
    <>
      {pois.map((poi, index) => (
        <CustomMarker
          key={index}
          latitude={poi.latitude}
          longitude={poi.longitude}
          onClick={(event) => {
            event.originalEvent.stopPropagation();
            setPopupInfo(poi);
          }}
          icon={poi.category.icon}
          alt={poi.category.name}
        />
      ))}
      {popupInfo && (
        <CustomPopup
          anchor="bottom"
          offset={16}
          latitude={popupInfo.latitude}
          longitude={popupInfo.longitude}
          closeButton={false}
          closeOnMove={true}
          onClose={() => setPopupInfo(undefined)}
          headline={popupInfo.text}
          description={`${popupInfo.distance.toFixed(0)}m`}
        />
      )}
    </>
  );
};

export default POIs;
