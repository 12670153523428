import { LngLatBoundsLike, ResourceType, Style } from 'mapbox-gl';
import maplibregl, { CameraOptions, RequestParameters } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { ReactNode, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Map, { FullscreenControl, NavigationControl, ViewState } from 'react-map-gl';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import mapStyles from '../../json/map/viamap-styles.json';
import { VIAMAP_ACCESS_TOKEN } from '../../utils/constants';
import LoadingIcon from '../LoadingIcon';
import POIs from './POIs';
import SatelliteControl from './SatelliteControl';
import ThreeDControl from './ThreeDControl';
import { useWhiteLabel } from 'providers/WhiteLabelProvider';

function viamapTransformRequest(url: string): RequestParameters {
  return {
    url,
    credentials: 'same-origin',
  };
}

interface MapViewProps {
  initialViewState: Partial<ViewState> & Pick<ViewState, 'latitude' | 'longitude'> & { bounds?: LngLatBoundsLike };
  scrollZoom?: boolean;
  supports3D?: boolean;
  showPOIs?: boolean;
  children?: ReactNode;
}

const MapView = ({ initialViewState, children, scrollZoom = true, supports3D = true, showPOIs = true }: MapViewProps) => {
  const [mapStyle, setMapStyle] = useState(mapStyles as Style);
  const [cameraOptions, setCameraOptions] = useState<CameraOptions>({});
  const windowSize = useGetWindowSize();
  const { ref, inView } = useInView({ triggerOnce: true });
  const { theme } = useWhiteLabel();

  return (
    <div ref={ref}>
      {inView ? (
        <Map
          style={{
            width: '100%',
            height: windowSize && windowSize.height && windowSize.height < 768 ? windowSize.height - 100 : 648,
            backgroundColor: theme.color.secondaryBackgroundColor,
            font: 'inherit',
          }}
          initialViewState={initialViewState}
          mapLib={maplibregl}
          mapStyle={mapStyle}
          styleDiffing
          {...cameraOptions}
          transformRequest={viamapTransformRequest}
          mapboxAccessToken={VIAMAP_ACCESS_TOKEN}
          scrollZoom={scrollZoom}
          attributionControl={false}
          pitchWithRotate={!supports3D}
          touchPitch={!supports3D}
        >
          <NavigationControl />
          <FullscreenControl />
          {supports3D && <ThreeDControl onChange={setCameraOptions} />}
          <SatelliteControl value={mapStyle} onChange={setMapStyle} />
          {showPOIs && <POIs viewState={initialViewState} />}
          {children}
        </Map>
      ) : (
        <LoadingIcon />
      )}
    </div>
  );
};

export default MapView;
